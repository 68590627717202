@import '/src/styles/utilities.scss';

.addFollowModal {
  display: grid;
  grid-template-columns: 1fr;
  gap: $space-16;
}

.addFollowModal__footer {
  display: flex;
  justify-content: space-between;
}

.addFollowModal__confirmation {
  display: inline-flex;
  align-items: center;
  color: var(--color-alert-success-300);
}
